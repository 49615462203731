var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("v-card", { staticClass: "card-principal-niky" }, [
        _c(
          "div",
          { staticClass: "custom-background-image" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "6", "align-self": "end" } },
                  [
                    _c("div", { staticClass: "custom-card" }, [
                      _vm.$vuetify.breakpoint.smAndUp
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/img/niky/niky_logo_simple.png"),
                              width: "60",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "h1",
                        {
                          class: {
                            "custom-title": true,
                            "mt-5": _vm.$vuetify.breakpoint.smAndUp,
                          },
                        },
                        [
                          _vm._v(" Liberte suas "),
                          _c("br"),
                          _vm._v(" premiações "),
                          _c("br"),
                          _vm._v(" com a Niky! "),
                        ]
                      ),
                      _c("h3", { staticClass: "custom-subtitle mt-5" }, [
                        _vm._v(" Junte-se aos milhares de "),
                        _c("br"),
                        _vm._v("parceiros que já descobriram "),
                        _c("br"),
                        _vm._v(" as vantagens do App "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "custom-div mt-7" },
                        [
                          _c("div", [
                            _c("img", {
                              staticClass: "mt-2",
                              attrs: {
                                src: require("@/assets/img/niky/niky_logo.png"),
                                width: "100",
                              },
                            }),
                          ]),
                          _c("v-divider", {
                            staticClass: "mx-5",
                            attrs: { vertical: "" },
                          }),
                          _c("div", [
                            _c("img", {
                              staticClass: "mt-2",
                              attrs: {
                                src: require("@/assets/img/niky/quali_logo.png"),
                                width: "150",
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("v-card", { staticClass: "card-funcionalidades-niky" }, [
        _vm.$vuetify.breakpoint.smAndUp
          ? _c(
              "div",
              { staticClass: "custom-background-image" },
              [
                _c("img", {
                  staticClass: "custom-image",
                  attrs: {
                    src: require("@/assets/img/niky/niky_cel_simple.png"),
                  },
                }),
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { offset: "6" } }, [
                      _c("h1", { staticClass: "custom-title" }, [
                        _vm._v("Com o App Niky, você pode:"),
                      ]),
                      _vm.$vuetify.breakpoint.smAndUp
                        ? _c(
                            "div",
                            { staticClass: "mt-5" },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    "no-gutters": "",
                                    align: "center",
                                    justify: "center",
                                  },
                                },
                                [
                                  _c("v-col", { attrs: { sm: "3", lg: "2" } }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/niky/func_01.png"),
                                        width: "60",
                                      },
                                    }),
                                  ]),
                                  _c("v-col", [
                                    _c(
                                      "span",
                                      { staticClass: "custom-strong-text" },
                                      [_vm._v("Controlar suas finanças.")]
                                    ),
                                    _c("br"),
                                    _c("span", { staticClass: "custom-text" }, [
                                      _vm._v(
                                        "Pague contas e acompanhe seu extrato."
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "my-2",
                                  attrs: {
                                    "no-gutters": "",
                                    align: "center",
                                    justify: "center",
                                  },
                                },
                                [
                                  _c("v-col", { attrs: { sm: "3", lg: "2" } }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/niky/func_02.png"),
                                        width: "60",
                                      },
                                    }),
                                  ]),
                                  _c("v-col", [
                                    _c(
                                      "span",
                                      { staticClass: "custom-strong-text" },
                                      [_vm._v("Gerenciar seu saldo.")]
                                    ),
                                    _c("br"),
                                    _c("span", { staticClass: "custom-text" }, [
                                      _vm._v(
                                        "Saque ou transfira seu Qualicash."
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    "no-gutters": "",
                                    align: "center",
                                    justify: "center",
                                  },
                                },
                                [
                                  _c("v-col", { attrs: { sm: "3", lg: "2" } }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/niky/func_03.png"),
                                        width: "60",
                                      },
                                    }),
                                  ]),
                                  _c("v-col", [
                                    _c(
                                      "span",
                                      { staticClass: "custom-strong-text" },
                                      [_vm._v("Aproveitar ao máximo.")]
                                    ),
                                    _c("br"),
                                    _c("span", { staticClass: "custom-text" }, [
                                      _vm._v(
                                        " Use seu saldo Niky em tudo: alimentação, "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " combustível, farmácia e muito mais! "
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "mb-3" }, [
                                _c(
                                  "h1",
                                  { staticClass: "custom-subtitle mb-5" },
                                  [_vm._v("E tem mais:")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "custom-strong-text" },
                                  [_vm._v("Você não paga anuidade nem fatura!")]
                                ),
                                _c("br"),
                                _c(
                                  "span",
                                  { staticClass: "custom-text mb-3" },
                                  [
                                    _vm._v(
                                      " Seus gastos são debitados na hora, direto da sua conta, "
                                    ),
                                    _c("br"),
                                    _vm._v(" sem cobranças no fim do mês. "),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "custom-background-image" },
              [
                _c("img", {
                  staticClass: "custom-image",
                  attrs: { src: require("@/assets/img/niky/niky_cel.png") },
                }),
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { offset: "6" } }, [
                      _c("h1", { staticClass: "custom-title" }, [
                        _vm._v("Com o App Niky, você pode:"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "custom-div" },
                  [
                    _c(
                      "v-row",
                      { attrs: { align: "center", justify: "center" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "text-center", attrs: { cols: "3" } },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/niky/func_01.png"),
                                width: "60",
                              },
                            }),
                          ]
                        ),
                        _c("v-col", [
                          _c("span", { staticClass: "custom-strong-text" }, [
                            _vm._v("Controlar suas finanças."),
                          ]),
                          _c("br"),
                          _c("span", { staticClass: "custom-text" }, [
                            _vm._v("Pague contas e acompanhe seu extrato."),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { attrs: { align: "center", justify: "center" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "text-center", attrs: { cols: "3" } },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/niky/func_02.png"),
                                width: "60",
                              },
                            }),
                          ]
                        ),
                        _c("v-col", [
                          _c("span", { staticClass: "custom-strong-text" }, [
                            _vm._v("Gerenciar seu saldo."),
                          ]),
                          _c("br"),
                          _c("span", { staticClass: "custom-text" }, [
                            _vm._v("Saque ou transfira seu Qualicash."),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { attrs: { align: "center", justify: "center" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "text-center", attrs: { cols: "3" } },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/niky/func_03.png"),
                                width: "60",
                              },
                            }),
                          ]
                        ),
                        _c("v-col", [
                          _c("span", { staticClass: "custom-strong-text" }, [
                            _vm._v("Aproveitar ao máximo."),
                          ]),
                          _c("br"),
                          _c("span", { staticClass: "custom-text" }, [
                            _vm._v(
                              " Use seu saldo Niky em tudo: alimentação, combustível, farmácia e muito mais! "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "pa-10" }, [
                  _c("h1", { staticClass: "custom-subtitle mb-5" }, [
                    _vm._v("E tem mais:"),
                  ]),
                  _c("span", { staticClass: "custom-strong-text" }, [
                    _vm._v("Você não paga anuidade nem fatura!"),
                  ]),
                  _c("br"),
                  _c("span", { staticClass: "custom-text" }, [
                    _vm._v(
                      " Seus gastos são debitados na hora, direto da sua conta, "
                    ),
                    _c("br"),
                    _vm._v(" sem cobranças no fim do mês. "),
                  ]),
                ]),
              ],
              1
            ),
      ]),
      _c("v-card", { staticClass: "card-download-niky" }, [
        _c(
          "div",
          { staticClass: "custom-background-image" },
          [
            _vm.$vuetify.breakpoint.lgAndUp
              ? _c("img", {
                  staticClass: "custom-image",
                  attrs: { src: require("@/assets/img/niky/niky_cel_02.png") },
                })
              : _vm._e(),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "7", "align-self": "end" } },
                  [
                    _c(
                      "div",
                      { staticClass: "custom-card-beneficios" },
                      [
                        _c("h1", { staticClass: "custom-title" }, [
                          _vm._v("Baixe o aplicativo Niky Benefícios"),
                        ]),
                        _c(
                          "v-row",
                          {
                            staticClass: "mt-7",
                            attrs: {
                              "no-gutters": _vm.$vuetify.breakpoint.mdAndDown,
                            },
                          },
                          [
                            _c("v-col", { attrs: { cols: "12", lg: "6" } }, [
                              _c("div", { staticClass: "custom-div" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/niky/download_01.png"),
                                  },
                                }),
                                _c("span", { staticClass: "custom-text" }, [
                                  _vm._v(" Abra o aplicativo e clique "),
                                  _c("br"),
                                  _vm._v(' em "Primeiro acesso". '),
                                ]),
                              ]),
                              _c("div", { staticClass: "custom-div" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/niky/download_02.png"),
                                  },
                                }),
                                _c("span", { staticClass: "custom-text" }, [
                                  _vm._v("Digite seu CPF e clique "),
                                  _c("br"),
                                  _vm._v(' em "continuar".'),
                                ]),
                              ]),
                              _c("div", { staticClass: "custom-div" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/niky/download_03.png"),
                                  },
                                }),
                                _c("span", { staticClass: "custom-text" }, [
                                  _vm._v(" Leia e aceite os termos "),
                                  _c("br"),
                                  _vm._v(" de uso e de privacidade. "),
                                ]),
                              ]),
                            ]),
                            _c("v-col", { attrs: { cols: "12", lg: "6" } }, [
                              _c("div", { staticClass: "custom-div" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/niky/download_04.png"),
                                  },
                                }),
                                _c("span", { staticClass: "custom-text" }, [
                                  _vm._v(" Insira o código de validação "),
                                  _c("br"),
                                  _vm._v(" que será enviado via SMS. "),
                                ]),
                              ]),
                              _c("div", { staticClass: "custom-div" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/niky/download_05.png"),
                                  },
                                }),
                                _c("span", { staticClass: "custom-text" }, [
                                  _vm._v(
                                    " Caso haja divergência de dados de e-mail ou celular, por favor acionar o Time Qualicorp. "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "custom-div" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/niky/download_06.png"),
                                  },
                                }),
                                _c("span", { staticClass: "custom-text" }, [
                                  _vm._v("Crie sua senha."),
                                ]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "custom-card-download-app" }, [
                      _c("h3", { staticClass: "custom-subtitle" }, [
                        _vm._v(
                          "Agora você já pode acessar sua conta Niky Benefícios!"
                        ),
                      ]),
                      _c("h1", { staticClass: "custom-title mt-7" }, [
                        _vm._v("Baixe o App agora mesmo."),
                      ]),
                      _c("div", { staticClass: "custom-download-div mt-10" }, [
                        _c(
                          "a",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              href: _vm.getLink("Android"),
                              target: "_blank",
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "mt-2",
                              attrs: {
                                src: require("@/assets/img/niky/btn_google_play.png"),
                              },
                            }),
                          ]
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.getLink("Apple"),
                              target: "_blank",
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "mt-2",
                              attrs: {
                                src: require("@/assets/img/niky/btn_app_store.png"),
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm.$vuetify.breakpoint.mdAndDown
                        ? _c("img", {
                            staticClass: "custom-image",
                            attrs: {
                              src: require("@/assets/img/niky/niky_cel_02.png"),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("v-card", { staticClass: "card-cartao-fisico-niky" }, [
        _c(
          "div",
          { staticClass: "custom-background-image custom-card" },
          [
            _c("h1", { staticClass: "custom-title" }, [
              _vm._v(" Mas e o cartão físico? "),
              _c("br"),
              _vm._v(" Tem também? "),
            ]),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "7", sm: "6", "align-self": "end" } },
                  [
                    _c("h3", { staticClass: "custom-subtitle mt-5" }, [
                      _vm._v(" Tem, sim! E a primeira via é grátis. "),
                      _c("br"),
                      _vm._v(
                        " Se você quiser, é só solicitar o seu cartão físico Niky, "
                      ),
                      _vm.$vuetify.breakpoint.mdAndUp ? _c("br") : _vm._e(),
                      _vm._v(
                        " um Mastercard prontinho pra uso, aceito em mais "
                      ),
                      _vm.$vuetify.breakpoint.mdAndUp ? _c("br") : _vm._e(),
                      _vm._v(" de 4 milhões de estabelecimentos do Brasil. "),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-card",
        { staticClass: "card-aprenda-niky" },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "8", lg: "6", xl: "4" } },
                    [
                      _c("h1", { staticClass: "custom-title" }, [
                        _vm._v(" Saiba como aproveitar ao "),
                        _c("br"),
                        _vm._v(" máximo seu App Niky ;) "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "custom-div" }, [
                _c("span", { staticClass: "custom-strong-text" }, [
                  _vm._v(" Solicite seu cartão físico. "),
                ]),
                _c("br"),
                _c("span", { staticClass: "custom-text" }, [
                  _vm._v(
                    ' Acesse a página inicial e selecione "Meus Cartões". Clique no botão para solicitar o cartão físico e, em seguida, confirme o endereço de entrega, preenchendo CEP, número e complemento. O cartão será solicitado e o prazo de entrega pode variar por localidade, levando até 10 dias úteis. '
                  ),
                ]),
              ]),
              _c(
                "v-expansion-panels",
                { attrs: { flat: "", accordion: "" } },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c("h2", [
                          _c("strong", [_vm._v("Ative seu cartão físico.")]),
                        ]),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("big", [
                            _c("strong", [
                              _vm._v("1. Primeiro acesso no app."),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _c("p", [
                              _vm._v(
                                " Baixe o aplicativo Niky Benefícios (Play Store ou App Store). Abra o App e clique em “Primeiro acesso”. Digite seu CPF e clique em “continuar”. Leia e aceite os termos de uso e privacidade. Insira o código de validação enviado via SMS (se houver divergência de dados de e-mail ou celular, acione o Time Qualicorp). Crie sua senha e acesse sua conta. "
                              ),
                            ]),
                            _c("strong", [
                              _vm._v("2. Desbloqueie seu cartão."),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _c("p", [
                              _vm._v(
                                " Faça login no aplicativo Niky Benefícios, clique em “Meu Niky” e depois em “Ativar cartão”. Digite o número do seu cartão e clique em “Ativar Cartão”. Escolha uma senha de quatro dígitos, clique em “Enviar” e pronto, seu cartão está pronto para uso. "
                              ),
                            ]),
                            _c("strong", [_vm._v("3. Primeiro uso.")]),
                            _vm._v(" "),
                            _c("br"),
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                " Faça uma compra presencial, insira o cartão na maquininha e digite a senha. Após essa etapa inicial, você poderá utilizar a função de aproximação para transações futuras. "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                { attrs: { flat: "", accordion: "" } },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c("h2", [
                          _c("strong", [
                            _vm._v("Consulte seu saldo e extrato."),
                          ]),
                        ]),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("big", [
                            _vm._v(
                              ' Abra o App da Niky Benefícios. Na tela inicial, você verá o saldo das suas carteiras. Se o saldo estiver com **** (asteriscos), clique no ícone de olho para visualizar o valor disponível. Para acessar seu extrato, clique no ícone "Extrato" na tela inicial, onde todas as suas despesas serão detalhadas. '
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                { attrs: { flat: "", accordion: "" } },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c("h2", [
                          _c("strong", [
                            _vm._v(
                              "Altere a senha do seu cartão Niky pelo App."
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("big", [
                            _vm._v(
                              ' Faça o login no aplicativo "Niky Benefícios", acesse a seção "Meu Niky", clique em "Senha" e selecione "Alterar senha". Digite sua senha atual e, em seguida, insira a nova para concluir a alteração. Se você esqueceu a senha do App, abra o aplicativo, clique em "Esqueci a senha" na tela de login e siga as instruções para redefini-la, o que geralmente envolve receber um e-mail com um link de redefinição ou responder a perguntas de segurança. '
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                { attrs: { flat: "", accordion: "" } },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c("h2", [
                          _c("strong", [
                            _vm._v("Entre em contato com o suporte."),
                          ]),
                        ]),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("big", [
                            _vm._v(
                              " Se você precisa de ajuda e quer entrar em contato com o suporte da Niky, você pode enviar um e-mail para faleconosco@niky.com.vc, acessar o site https://bit.ly/suporte-niky ou ligar para 3003-7316 ou 0800-575-0069. O suporte está disponível de segunda a sexta-feira, das 8h às 18h (exceto feriados). "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                { attrs: { flat: "", accordion: "" } },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c("h2", [
                          _c("strong", [
                            _vm._v("Solicite a segunda via do cartão."),
                          ]),
                        ]),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("big", [
                            _vm._v(
                              " Para solicitar a segunda via do cartão Niky, você pode entrar em contato diretamente com seu departamento de Premiações Qualicorp. Caso não disponibilizem um cartão reserva, eles devem encaminhar sua solicitação ao suporte. "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                { attrs: { flat: "", accordion: "" } },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c("h2", [
                          _c("strong", [
                            _vm._v(
                              "Transfira dinheiro via TED para qualquer banco."
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("big", [
                            _vm._v(
                              " Acesse o App e selecione “Serviços Financeiros” no menu principal. Escolha “Transferência bancária” e siga as instruções, confirmando os dados da conta do destinatário, que pode ser sua ou de outra pessoa. A tarifa de serviço para TED é de R$ 9,90 e será exibida após a confirmação da transação. O valor será creditado na conta indicada em até 1 dia útil. "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                { attrs: { flat: "", accordion: "" } },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c("h2", [
                          _c("strong", [_vm._v("Pague contas e boletos.")]),
                        ]),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("big", [
                            _vm._v(
                              " Acesse o App e selecione “Serviços Financeiros” no menu principal. Clique em “Pagar Boleto” e selecione a carteira para uso do saldo. Digite o código do boleto a ser pago ou selecione “Escanear” e aponte a câmera do celular para o código de barras, aguardando a leitura. Verifique as informações de pagamento, clique em “Continuar” e digite sua senha. Pronto, boleto pago (a taxa de pagamento de boleto é de R$ 3,00). "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                { attrs: { flat: "", accordion: "" } },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c("h2", [_c("strong", [_vm._v("Faça saques.")])]),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("big", [
                            _vm._v(
                              " Insira o cartão em um terminal do Banco24h e selecione “Saque a Crédito”. Siga as instruções no caixa eletrônico e pronto! A tarifa de serviço cobrada pela rede 24h para essa transação com o cartão físico é de R$ 9,90. "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "card-contato-niky", attrs: { color: "#18264C" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "8", lg: "6", xl: "4" } },
                    [
                      _c("h1", { staticClass: "custom-title" }, [
                        _vm._v("Ficou com alguma dúvida?"),
                        _c("br"),
                        _vm._v("Fale com o TamoJunto no WhatsApp:"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "flex-container mt-5" }, [
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/niky/contato_whatsapp.png"),
                      width: "50",
                    },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column flex-30 ml-0 ml-md-3 mt-1",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "contato-title",
                        attrs: {
                          href: "https://api.whatsapp.com/send?phone=5511930304630",
                          target: "_blank",
                        },
                      },
                      [_vm._v("(11) 93030-4630")]
                    ),
                    _c("span", { staticClass: "contato-subtitle" }, [
                      _vm._v("(Brasil)"),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "d-flex flex-column mt-5 mt-md-0" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/niky/contato_niky.png"),
                      width: "50",
                    },
                  }),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column flex-50 ml-0 ml-md-3 mt-2 mt-md-1 mt-xl-1",
                  },
                  [
                    _c("div", { staticClass: "contato-title" }, [
                      _vm._v(" Ou envie uma mensagem pelo "),
                      _c("br"),
                      _vm._v(' App Niky na opção "Fale Conosco". '),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }